@use 'reset';

* {
	box-sizing: border-box;
}

body {
	background-color: #070707;
	color: white;
	font-family: 'Montserrat', sans-serif;
	line-height: 32px;
}

.container {
	margin: 0 auto;
	max-width: 1920px;
	padding: 0px 75px;
}

.small-container {
	margin: 0 auto;
	max-width: 1920px;
	padding: 0px 225px;
}

.row {
	display: flex;
}

// ---------------------- Adaptive ----------------------

// ------------- Desctop -------------
@media (max-width: 1500px) {
	.small-container {
		padding: 0px 125px;
	}
}

// ------------- Laptop -------------
@media (max-width: 1200px) {
	.small-container {
		padding: 0px 75px;
	}
}

// ------------- Tablet -------------
@media (max-width: 992px) {
	.container,
	.small-container {
		padding: 0px 30px;
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.container,
	.small-container {
		padding: 0px 16px;
	}
}
