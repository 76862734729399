.pop-up-form {
	.form-bg {
		position: fixed;
		top: 0px;
		left: 0px;
		background: #ffffff33;
		backdrop-filter: blur(7px);
		width: 100%;
		height: 100%;
		z-index: 1200;
	}
	.form-popup-wrapper {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #121212;
		padding: 30px 30px;
		border-radius: 16px;
		width: 700px;
		border: 2px solid #ffffff;
		box-shadow: 0px 4px 20px 0px #00000073;
		z-index: 1500;
		.close-btn {
			background-image: url(../../assets/close-img.svg);
			background-position: center;
			background-repeat: no-repeat;
			min-width: 24px;
			height: 24px;
			background-color: transparent;
			border: none;
			position: absolute;
			right: 16px;
			top: 16px;
			z-index: 1000;
			cursor: pointer;
		}
	}
}

.active {
	display: block;
}

.not-active {
	display: none;
}

// ---------------------- Adaptive ----------------------

// ------------- Laptop -------------
@media (max-width: 1200px) {
	.pop-up-form {
		.form-wrapper {
			width: 70%;
		}
	}
}

// ------------- Tablet -------------
@media (max-width: 992px) {
	.pop-up-form {
		.form-popup-wrapper {
			width: 80%;
			top: 20px;
			bottom: 20px;
			transform: translate(-50%, 0%);
			padding: 20px 20px;
			padding-bottom: 40px;
			max-height: 750px;
			overflow-y: scroll;
			overflow-x: hidden;
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.pop-up-form {
		.form-popup-wrapper {
			width: 90%;
		}
	}
}
