.map-section {
	position: relative;
	overflow: hidden;
	.anchor-element {
		position: absolute;
		top: -100px;
	}
	.close-map-btn {
		position: absolute;
		bottom: 50px;
		left: 20px;
		z-index: 1000;
		.btn-arrow {
			transform: rotate(180deg);
		}
	}
	.close-map-btn.map-not-active {
		transform: translateX(-300%);
	}
	.close-map-btn.map-active {
		transition: 1s all;
		transform: translateX(0%);
	}
	.map-wrapper {
		display: grid;
		grid-template-columns: 62% 38%;
		height: 740px;
		background-color: #1e222d;
		overflow: hidden;
		transition: grid-template-columns 1s;
	}
	.map-wrapper.map-active {
		grid-template-columns: 100% 40%;
		.map-content {
			transform: translateX(110%);
		}
		.map-preview-container {
			> img {
				width: 100%;
			}
		}
	}
	.map-preview-container {
		background-image: url(../../../../../public/images/map-preview.jpg);
		background-position: top left;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		filter: grayscale(100%);
		> img {
			height: 740px;
			width: auto;
			filter: grayscale(100%);
			transition: width 1s;
		}
	}
	.map-content {
		background-color: #1e222d;
		position: relative;
		transform: translateX(0%);
		transition: all 1s;
		.map-text {
			position: absolute;
			width: 530px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: transform 0.7s;
			.map-content-header {
				font-size: 64px;
				font-weight: 700;
				line-height: 77px;
				letter-spacing: -0.06em;
				text-transform: uppercase;
				margin-bottom: 24px;
			}
			.subtitle {
				color: white;
			}
			.big-header:nth-child(2) {
				margin-bottom: 24px;
			}
			.subtitle {
				margin-bottom: 32px;
			}
		}
	}
	.map-overlap {
		filter: grayscale(100%);
		height: 100%;
		transition: 0.5s all;
		&:hover {
			filter: grayscale(0%);
		}
	}
	.map {
		width: 100%;
		height: 100%;
	}
	.mobile-button {
		display: none;
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Large Desktop -------------
@media (max-width: 1500px) {
	.map-section {
		.map-content {
			.map-text {
				width: 450px;
			}
		}
	}
}

@media (max-width: 1300px) {
	.map-section {
		.map-content {
			height: auto;
			.map-text {
				width: 350px;
			}
		}
		.map-overlap {
			min-height: 400px;
			// filter: grayscale(0%);
		}
	}
}

@media (max-width: 1020px) {
	.map-section {
		.map-content {
			.map-text {
				right: 0px;
			}
		}
	}
}

// ------------- Tablet -------------
@media (max-width: 992px) {
	.map-section {
		.map-overlap {
			transition: none;
		}
		.map-content {
			.map-text {
				position: relative;
				width: 100%;
				padding: 35px 16px;
			}
		}
		.map-wrapper {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 1fr;
			height: auto;
		}
		.map-not-active {
			.map-content {
				opacity: 1;
				visibility: visible;
				transition: opacity 0.5s;
			}
		}
		.map-active {
			grid-template-rows: 1fr 0fr;
			transition: grid 0.5s;
			.map-overlap {
				height: 75vh;
			}
			.map-content {
				height: 0px;
				padding: 0px;
				opacity: 0;
				visibility: hidden;
				transform: translateX(0%);
				transition: padding 0.5s;
				.map-text {
					transform: translate(-50%, -50%);
					height: 0px;
					padding: 0px;
				}
			}
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.map-section {
		position: relative;
		.anchor-element {
			margin-top: -130px;
			top: auto;
		}
		.map {
			overflow: hidden;
		}
		.map-wrapper {
			grid-template-rows: 300px 1fr;
		}
		.map-content {
			.map-text {
				padding-top: 24px;
				padding-bottom: 32px;
				.map-content-header {
					font-size: 24px;
					line-height: 28.8px;
					letter-spacing: -0.06em;
					text-align: center;
					margin-bottom: 16px;
				}
			}
			.button {
				width: 100%;
			}
		}
		.close-map-btn.map-active {
			transform: translate(-10%, 60%);
		}
		.map-active {
			grid-template-rows: 70vh 0fr;
		}
	}
}

// ------------- Small Mobile -------------
@media (max-width: 400px) {
	.map-section {
		overflow: auto;
		position: static;
		.map-wrapper {
			display: block;
		}
		.map-overlap {
			display: none;
		}
		.map-content {
			position: static;
			.map-text {
				position: static;
				transform: translate(0%, 0%);
			}
		}
		.desktop-button {
			display: none;
		}
		.mobile-button {
			display: block;
		}
	}
}
