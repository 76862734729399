.page-title-wrapper {
	height: calc(100vh - 60px);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	border-bottom-left-radius: 50px;
	border-bottom-right-radius: 50px;
	margin-top: 60px;
	.page-title-bg {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-color: #00000066;
	}
	.page-title-container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		text-align: center;
		z-index: 10;
		.page-title {
			font-size: 160px;
			font-weight: 700;
			line-height: 195px;
			text-transform: uppercase;
		}
	}
	.page-subtitle {
		font-size: 20px;
		font-weight: 700;
		line-height: 20px;
		text-transform: lowercase;
	}
	.page-title-link {
		margin: 0 auto;
		font-size: 16px;
		font-weight: 700;
		line-height: 16px;
		text-decoration: none;
		color: white;
		width: fit-content;
		.arrow-img {
			background-image: url(../../assets/small-arrow-right.svg);
			background-position: center;
			background-repeat: no-repeat;
			width: 24px;
			height: 24px;
			transform: rotate(180deg);
		}
	}
	.page-link-row {
		align-items: center;
		justify-content: center;
		gap: 8px;
		margin-top: 24px;
	}
}

.post-page {
	.page-title-wrapper {
		.page-title {
			font-size: 64px;
			line-height: 78px;
			max-width: 1000px;
			margin: 0 auto;
		}
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Laptop -------------
@media (max-width: 1200px) {
	.page-title-wrapper {
		.page-title-container {
			.page-title {
				padding: 0px 16px;
				font-size: 100px;
				line-height: 120px;
			}
		}
	}
	.post-page {
		.page-title-wrapper {
			.page-title {
				font-size: 64px;
				line-height: 78px;
			}
		}
	}
}

// ------------- Tablet -------------
@media (max-width: 992px) {
	.page-title-wrapper {
		.page-title-container {
			.page-title {
				font-size: 80px;
				line-height: 100px;
			}
		}
	}
	.post-page {
		.page-title-wrapper {
			.page-title {
				font-size: 49px;
				line-height: 60px;
			}
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.page-title-wrapper {
		height: calc(100vh - 114px);
		margin-top: 114px;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		.page-title-container {
			.page-title {
				font-size: 40px;
				line-height: 49px;
			}
		}
		.page-title-link,
		.page-link-row {
			display: none;
		}
	}
	.post-page {
		.page-title-wrapper {
			.page-title {
				font-size: 20px;
				line-height: 30px;
			}
		}
	}
}
