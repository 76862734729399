.faq-page {
	.faq-page-container {
		margin: 0 auto;
		max-width: 1920px;
		padding: 0px 375px;
	}

	.dictionary-page-container {
		margin: 0 auto;
		max-width: 1920px;
		padding-right: 375px;
		padding-left: 245px;
	}

	.tablet-tab-buttons-container {
		display: none;
		padding: 0px 30px;
		.active {
			color: #fe6700;
		}
	}

	.no-matches-result {
		margin: 0 auto;
		> p {
			font-family: 'Roboto', sans-serif;
			font-weight: 700;
			text-transform: uppercase;
			font-size: 29px;
		}
	}

	.page-content {
		font-family: 'Roboto', sans-serif;
	}
	p {
		font-weight: 300;
		span {
			font-weight: 700;
		}
	}
	.page-title {
		text-transform: uppercase;
		font-size: 18px;
		font-weight: 700;
		line-height: 22px;
		margin-top: 24px;
		margin-bottom: 8px;
	}
	.bold-text {
		font-weight: 600;
	}
	.big-text {
		font-family: 'Montserrat', sans-serif;
		font-size: 24px;
		font-weight: 700;
		line-height: 29px;
		margin-bottom: 8px;
	}
	.margin-bottom {
		margin-bottom: 24px;
	}
	.orange-text {
		color: #fe6700;
	}
	.page-img {
		text-align: center;
		margin-top: 24px;
		> img {
			object-fit: contain;
			object-position: center;
			max-height: 780px;
			width: 100%;
		}
	}

	.page-content-table {
		width: 100%;
		border: 1px solid #ffffff;
		text-transform: uppercase;
		margin: 24px 0px;
		thead {
			background: #ffffff1a;
		}
		th,
		td {
			border: 1px solid #ffffff;
			padding: 18px 0px;
		}
		td {
			text-align: center;
		}
		.table-cell-alighn-left {
			text-align: left;
			padding-left: 36px;
		}
	}
}

.dictionary-page {
	.dictionary-container {
		display: flex;
		gap: 75px;
	}
	.dictionary-page-scroll-anker {
		position: absolute;
		bottom: 50px;
	}
	.tab-buttons-container {
		.active {
			color: #fe6700;
		}
	}
	.tab-button {
		font-family: 'Roboto', sans-serif;
		font-size: 32px;
		font-weight: 700;
		line-height: 52px;
		cursor: pointer;
	}
	.dictionary-item {
		margin-bottom: 24px;
	}
	.dictionary-item-title {
		font-weight: 600;
	}
	.dictionary-item-text {
		font-weight: 300;
	}
}

// ---------------------- Adaptive ----------------------

@media (max-width: 1500px) {
	.faq-page {
		.faq-page-container {
			padding: 0px 175px;
		}
		.dictionary-page-container {
			padding-right: 175px;
			padding-left: 145px;
		}
	}
}

// ------------- Laptop -------------
@media (max-width: 1200px) {
	.faq-page {
		.faq-page-container {
			padding: 0px 100px;
		}
		.dictionary-page-container {
			padding-right: 135px;
			padding-left: 100px;
		}
	}
}

// ------------- Tablet -------------
@media (max-width: 992px) {
	.faq-page {
		.faq-page-container {
			padding: 0px 30px;
		}
		.dictionary-page-container {
			padding-right: 30px;
			padding-left: 30px;
		}
		.tab-buttons-container {
			display: none;
		}
		.tablet-tab-buttons-container {
			display: flex;
			flex-direction: column;
			position: sticky;
			top: 238px;
			background-color: #070707;
			padding-top: 24px;
			margin-bottom: 24px;
			.tab-button {
				margin-left: 20px;
				margin-right: 20px;
			}
			.underline {
				margin-top: 24px;
			}
		}
		.underline {
			width: 100%;
			height: 2px;
			background-color: #ffffff;
		}
	}
	.dictionary-page {
		.dictionary-page-scroll-anker {
			bottom: 100px;
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.faq-page {
		.faq-page-container {
			padding: 0px 16px;
		}
		.tablet-tab-buttons-container {
			padding-left: 16px;
			padding-right: 16px;
			top: 213px;
			.tab-button {
				font-size: 18px;
				line-height: 21px;
				margin-left: 9px;
				margin-right: 9px;
			}
		}
		.dictionary-page-container {
			padding-right: 16px;
			padding-left: 16px;
		}
		.page-title {
			margin-top: 16px;
		}
		.page-content-table {
			font-size: 12px;
			line-height: 17px;
			.table-cell-alighn-left {
				padding-left: 13px;
			}
		}
	}
	.dictionary-page {
		.dictionary-page-scroll-anker {
			bottom: 110px;
		}
	}
}
