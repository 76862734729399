.page-sidebar {
	.page-sidebar-nav {
		padding: 40px 90px 40px 50px;
		background: #1e222d;
		.page-sidebar-nav-about {
			margin-bottom: 130px;
			> p:nth-child(1) {
				font-size: 24px;
				font-weight: 700;
				line-height: 29px;
				margin-bottom: 16px;
				text-transform: uppercase;
			}
			> p:nth-child(2) {
				line-height: 26px;
			}
		}
		.page-sidebar-nav-links {
			.nav-link-item {
				margin-bottom: 32px;
				a {
					text-decoration: none;
					color: #fe6700;
					font-size: 20px;
					font-weight: 700;
					line-height: 20px;
					text-transform: uppercase;
					> p {
						margin-bottom: 16px;
					}
				}
				.nav-link-item-description {
					font-family: 'Roboto', sans-serif;
					font-size: 14px;
					font-weight: 400;
					line-height: 26px;
					color: #bdbabac7;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	.page-sidebar-widjet {
		margin-top: 32px;
		height: 780px;
		background: #1e222d;
		.sidebar-tradingview-widget-container__widget {
			height: 780px;
		}
		.tradingview-widget-copyright {
			display: none;
		}
	}
}

.post-page {
	.page-sidebar {
		.page-sidebar-nav {
			.page-sidebar-nav-links {
				.nav-link-item {
					a {
						font-size: 16px;
						font-weight: 700;
						line-height: 26px;
					}
				}
			}
		}
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Desctop -------------
@media (max-width: 1500px) {
	.page-sidebar {
		.page-sidebar-nav {
			padding: 40px 50px;
		}
	}
}

// ------------- Laptop -------------
@media (max-width: 1200px) {
	.page-sidebar {
		.page-sidebar-nav {
			padding: 24px 16px;
		}
	}
}

// ------------- Tablet -------------
@media (max-width: 992px) {
	.page-sidebar {
		.page-sidebar-nav {
			.page-sidebar-nav-about {
				margin-bottom: 48px;
			}
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.page-sidebar {
		.page-sidebar-widjet {
			margin-top: 24px;
		}
	}
}
