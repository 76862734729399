.subscribe-section {
	padding-top: 80px;
	position: relative;
	height: 470px;
	background-color: #070707;
	.subscribe-form {
		justify-content: space-between;
		background: linear-gradient(99.62deg, #666fc7 -7.54%, #b252c1 78.88%);
		border: none;
		border-radius: 16px;
		padding: 55px 58px;
		position: relative;
		gap: 20px;
		z-index: 100;
		.subscribe-form-text {
			font-size: 24px;
			font-weight: 700;
			line-height: 34px;
			text-transform: uppercase;
			span {
				text-wrap: nowrap;
			}
		}
		.form {
			gap: 16px;
			align-items: center;
			.form-input {
				width: 300px;
				padding: 12px 16px;
				border: none;
				border-radius: 8px;
				font-family: 'Montserrat', sans-serif;
				line-height: 24px;
				color: #505050;
				&::placeholder {
					font-family: 'Montserrat', sans-serif;
					line-height: 24px;
					color: #505050;
				}
			}
			.form-btn {
				width: 200px;
				font-weight: 700;
				line-height: 24px;
				padding: 12px 16px;
				background-color: #121212;
				color: white;
				border: 2px solid transparent;
				border-radius: 8px;
				cursor: pointer;
			}
		}
	}
	.form-glowing {
		position: absolute;
		top: 100px;
		left: 50%;
		transform: translateX(-50%);
		background: transparent;
		width: 40%;
		height: 150px;
		border-radius: 50%;
		box-shadow: 1px 25px 100px 25px #d484e880;
		z-index: 1;
	}
	.subscribe-bg-static {
		display: none;
	}
	.subscribe-bg-1,
	.subscribe-bg-2 {
		position: absolute;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.subscribe-bg-1 {
		top: -140px;
		left: -130px;
		width: 550px;
		height: 550px;
		background-image: url(../../../../../public/images/subscribe-section-img-1.png);
	}
	.subscribe-bg-2 {
		z-index: 100;
		transform: rotate(-25deg);
		top: 100px;
		right: 90px;
		width: 550px;
		height: 550px;
		background-image: url(../../../../../public/images/subscribe-section-img-2.png);
	}
	.subscribe-bg-2.hide {
		display: none;
	}
}

.footer-page {
	.subscribe-section {
		margin-top: 180px;
		.page-logo-transparent {
			position: absolute;
			top: -140px;
			right: 0px;
			width: 450px;
			height: 126px;
			background-image: url(../../../../assets/footer-logo-transparent.png);
		}
	}
}

@media (hover: hover) {
	.subscribe-section {
		.subscribe-form {
			transition: box-shadow 0.3s;
			&:hover {
				box-shadow: 1px 10px 100px 25px #d484e880;
			}
			.form-btn {
				transition: border 0.3s;
				&:hover {
					border: 2px solid white;
				}
			}
		}
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Large Desktop -------------
@media (max-width: 1500px) {
	.subscribe-section {
		.subscribe-form {
			.form {
				flex-direction: column;
				.form-btn {
					width: 100%;
				}
			}
		}
	}
}

// ------------- Laptop -------------
@media (max-width: 1350px) {
	.subscribe-section {
		.subscribe-bg-2 {
			display: none;
		}
	}
}

// ------------- Tablet -------------
@media (max-width: 992px) {
	.subscribe-section {
		margin-bottom: 36px;
		.subscribe-bg-static {
			display: block;
			position: absolute;
			left: -20px;
			top: 45px;
			width: 100px;
			height: 100px;
			background-image: url(../../../../../public/images/subscribe-section-img-1.png);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
		.subscribe-bg-1 {
			display: none;
		}
		.subscribe-form {
			flex-direction: column;
			gap: 56px;
			.form {
				.form-input {
					width: 100%;
				}
			}
		}
		.page-logo-transparent {
			display: none;
		}
	}
	.footer-page {
		.subscribe-section {
			margin-top: 0px;
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.subscribe-section {
		padding-top: 48px;
		margin-bottom: 0;
		.subscribe-bg-static {
			top: 15px;
		}
		.subscribe-form {
			padding: 24px;
			.subscribe-form-text {
				font-size: 20px;
				line-height: 34px;
			}
		}
	}
	.footer-page {
		.subscribe-section {
			margin-top: 0;
		}
	}
}
