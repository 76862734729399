.services-page {
	.services-links-row {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		padding-top: 60px;
		padding-bottom: 32px;
		gap: 26px;
	}
	.contact-button-container {
		background-color: #1e222d;
		padding: 70px 0px;
		text-align: center;
		margin-bottom: 115px;
		.contact-container-btn {
			color: #333e46;
			padding: 16px 0px;
			width: 570px;
			background-color: #fe6700;
			border: none;
			font-family: 'Montserrat', sans-serif;
			font-weight: 700;
			margin-top: 54px;
			cursor: pointer;
			.contact-container-btn-row {
				gap: 8px;
				align-items: center;
				justify-content: center;
			}
			.arrow-img {
				width: 20px;
				height: 20px;
				background-image: url(../../assets/black-arrow-right-img.svg);
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}
	.form-bg {
		padding-bottom: 200px;
		position: relative;
		overflow: hidden;
		.form-anker {
			position: absolute;
			top: -116px;
		}
		.form-bg-img {
			position: absolute;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
		.form-bg-img-1 {
			background-image: url(../../assets/experiense-bg-1.png);
			top: 0px;
			left: -200px;
			width: 530px;
			height: 530px;
		}
		.form-bg-img-2 {
			bottom: -100px;
			right: -100px;
			width: 630px;
			height: 630px;
			background-image: url(../../../public/images/subscribe-section-img-2.png);
		}
		.form-container {
			margin: 0 auto;
			width: 700px;
		}
	}
}

@media (hover: hover) {
	.services-page {
		.contact-button-container {
			.contact-container-btn {
				transition: box-shadow 0.5s, border-radius 0.5s;
				.arrow-img {
					transition: transform 0.5s;
				}
				&:hover .arrow-img {
					transform: translateX(10px);
				}
				&:hover {
					box-shadow: 0px 7px 32px 0px rgba(254, 103, 0, 0.6);
					border-radius: 8px;
				}
			}
		}
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Laptop -------------
@media (max-width: 1200px) {
}

// ------------- Tablet -------------
@media (max-width: 992px) {
	.services-page {
		.services-links-row {
			grid-template-columns: 1fr;
			justify-items: center;
			> a {
				max-width: 500px;
			}
		}
		.contact-button-container {
			padding-left: 16px;
			padding-right: 16px;
			.contact-container-btn {
				max-width: 570px;
				width: 100%;
			}
		}
		.form-bg {
			.form-bg-img {
				display: none;
			}
			.form-container {
				width: 100%;
				padding: 0px 16px;
			}
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.services-page {
		.services-links-row {
			padding-top: 32px;
			padding-bottom: 48px;
		}
		.contact-button-container {
			padding: 24px 16px;
			border-radius: 16px;
			margin-bottom: 48px;
			.contact-container-btn {
				margin-top: 30px;
				font-size: 16px;
			}
		}
		.form-bg {
			padding-bottom: 48px;
			.form-anker {
				top: -130px;
			}
		}
	}
}
