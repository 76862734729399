.links-slider {
	padding-top: 77px;
	position: sticky;
	top: 60px;
	background-color: #070707;
	.slider-item {
		text-transform: uppercase;
		text-align: center;
		font-family: 'Roboto', sans-serif;
		font-size: 32px;
		font-weight: 700;
		line-height: 38px;
		user-select: none;
		margin-right: 50px;
		min-width: fit-content;
		.active {
			color: #fe6700;
		}
	}
	.underline {
		width: 100%;
		height: 2px;
		background-color: #ffffff;
		margin-top: 31px;
		margin-bottom: 44px;
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Tablet -------------
@media (max-width: 992px) {
	.links-slider {
		padding-top: 24px;
		top: 106px;
		z-index: 500;
		.flicking-camera {
			align-items: center;
		}
		.slider-item {
			min-width: none;
			width: 70%;
		}
		.underline {
			margin-bottom: 24px;
		}
	}
	.dictionary-page {
		.links-slider {
			.underline {
				margin-bottom: 0;
			}
		}
	}
}

// ------------- Small Tablet -------------
@media (max-width: 770px) {
	.links-slider {
		top: 133px;
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.links-slider {
		top: 114px;
		.slider-item {
			font-size: 18px;
			line-height: 21.6px;
			margin-right: 24px;
		}
	}
}
