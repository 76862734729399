.button {
	background: linear-gradient(114.92deg, #3981cc -43.3%, #d743be 132.23%);
	text-transform: uppercase;
	border: none;
	border-radius: 8px;
	padding: 16px 24px;
	cursor: pointer;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	line-height: 20px;
	color: #ffffff;
	box-shadow: 0px 7px 32px 0px #d484e899;
	.btn-content-row {
		gap: 8px;
	}
	.btn-arrow {
		width: 20px;
		height: 20px;
		background-image: url(../../assets/small-arrow-right.svg);
		background-position: center;
		background-repeat: no-repeat;
	}
}

@media (hover: hover) {
	.button {
		transition: box-shadow 0.5s;
		&:hover {
			box-shadow: 0px 7px 46px 10px #d484e899;
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.button {
		.btn-content-row {
			justify-content: center;
		}
	}
}
