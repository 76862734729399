.form-component {
	width: 100%;
	z-index: 500;
	position: relative;
	.subtitle {
		font-size: 19px;
		font-weight: 700;
		line-height: 23px;
		color: #fe6700;
		text-align: center;
	}
	.title {
		font-size: 48px;
		font-weight: 700;
		line-height: 59px;
		margin-top: 16px;
		margin-bottom: 24px;
		text-align: center;
	}
	.form {
		.input-row {
			gap: 30px;
			margin-bottom: 16px;
		}
		.input-item-row {
			width: 50%;
		}
		.label,
		.form-input,
		.form-textarea {
			display: block;
			resize: none;
		}
		.form-input,
		.form-textarea {
			border: 1px solid #ffffff66;
			padding: 10px 16px;
			background-color: #070707;
			border-radius: 4px;
			font-family: 'Roboto', sans-serif;
			font-size: 14px;
			font-weight: 400;
			line-height: 25px;
			color: #fe6700;
			&::placeholder {
				font-family: 'Roboto', sans-serif;
				font-size: 14px;
				font-weight: 400;
				line-height: 25px;
				color: #fe6700;
				opacity: 0.6;
			}
		}
		.label {
			font-family: 'Roboto', sans-serif;
			font-size: 14px;
			line-height: 25px;
		}
		.form-input {
			width: 100%;
		}
		.form-textarea {
			width: 100%;
			height: 200px;
		}
		.btn-container {
			text-align: center;
			margin-top: 16px;
		}
	}
}

.contacts-page-section {
	.form-component {
		width: 100%;
		.subtitle {
			text-align: left;
		}
		.title {
			text-align: left;
		}
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Laptop -------------
@media (max-width: 1200px) {
}

// ------------- Tablet -------------
@media (max-width: 992px) {
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.form-component {
		.title {
			font-size: 18px;
			line-height: 21.94px;
			margin-top: 16px;
			margin-bottom: 48px;
		}
		.form {
			.input-row {
				flex-direction: column;
				gap: 16px;
			}
			.input-item-row {
				width: 100%;
			}
			.form-textarea {
				height: 100px;
			}
			.btn-container {
				margin-top: 32px;
				.form-btn {
					width: 100%;
				}
			}
		}
	}
}
