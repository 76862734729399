.experience-section {
	padding-bottom: 140px;
	position: relative;
	overflow: hidden;
	.experience-sectio-anker {
		// margin-bottom: 106px;
		position: absolute;
		top: -106px;
	}
	.experience-title-wrapper {
		margin-left: 105px;
		background-color: #07070763;
		position: relative;
		padding: 16px;
		border-radius: 10px;
		.experience-title {
			font-size: 64px;
			font-weight: 700;
			line-height: 77px;
			margin-bottom: 24px;
			text-transform: uppercase;
		}
		.experience-subtitle {
			line-height: 27px;
			width: 75%;
		}
	}
	.experience-content-wrapper {
		margin-top: 55px;
		margin-left: 200px;
		background-color: #07070763;
		position: relative;
		padding: 16px;
		border-radius: 10px;
		.experience-text {
			margin-bottom: 24px;
		}
	}
	.experience-section-bg {
		position: absolute;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}
	.experience-section-bg-1 {
		top: 0px;
		left: -200px;
		width: 530px;
		height: 530px;
		background-image: url(../../../../assets/experiense-bg-1.png);
	}
	.experience-section-bg-2 {
		bottom: 0px;
		right: -100px;
		width: 370px;
		height: 370px;
		background-image: url(../../../../assets/experiense-bg-2.png);
	}
	.experience-download-link {
		width: fit-content;
		margin-top: 26px;
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Tablet -------------
@media (max-width: 992px) {
	.experience-section {
		.experience-title-wrapper {
			margin-left: 0;
			padding: 0;
			.experience-title {
				text-align: center;
			}
			.experience-subtitle {
				width: 100%;
			}
		}
		.experience-content-wrapper {
			margin-left: 0;
			padding: 0;
			margin-top: 24px;
		}
		.experience-section-bg-1,
		.experience-section-bg-2 {
			display: none;
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.experience-section {
		padding-bottom: 48px;
		.experience-sectio-anker {
			top: -130px;
		}
		.experience-title-wrapper {
			.experience-title {
				font-size: 24px;
				line-height: 29px;
				margin-bottom: 16px;
			}
		}
		.experience-content-wrapper {
			margin-top: 16px;
		}
		.experience-download-link {
			width: 100%;
			margin-top: 26px;
			.experience-btn {
				width: 100%;
			}
		}
	}
}
