.header {
	position: fixed;
	top: 0px;
	width: 100%;
	z-index: 1000;
	background-color: #070707;
	a {
		text-decoration: none;
	}
	.header-row {
		max-height: 70px;
		justify-content: space-between;
		align-items: center;
		.logo-link {
			margin: 10px 0px;
			height: 47px;
			width: 170px;
			background-image: url(../../assets/footer-logo.png);
			background-position: 50% 45%;
			background-repeat: no-repeat;
			background-size: cover;
		}
		.header-logo {
			position: relative;
			top: 18px;
			width: 300px;
			height: 100%;
			> img {
				width: 100%;
			}
		}
		.header-menu {
			gap: 80px;
			max-height: 60px;
			.header-menu-item-wrapper {
				position: relative;
			}
			.header-menu-item {
				display: block;
				text-decoration: none;
				color: white;
				font-weight: 700;
				line-height: 37px;
				cursor: pointer;
			}
			.header-menu-item-link.active {
				.header-menu-item {
					color: #fe6700;
				}
			}
			.header-menu-item-dropdown {
				position: absolute;
				top: 28px;
				left: 0px;
				z-index: 1500;
				width: 155px;
				background-color: #070707;
				padding: 22px 35px 22px 13px;
				font-weight: 500;
				line-height: 37px;
				.active {
					.dropdown-link-wrapper {
						color: #fe6700;
						.link-circle {
							background-color: #fe6700;
						}
					}
				}
				.dropdown-link-wrapper {
					align-items: center;
					gap: 8px;
					.link-circle {
						width: 6px;
						height: 6px;
						border-radius: 50%;
						background-color: transparent;
					}
				}
			}
			.header-menu-item-dropdown-products {
				display: none;
			}
			.header-menu-item-dropdown-services {
				width: 195px;
				display: none;
			}
		}
		.burger-menu-btn {
			background-image: url(../../assets/burger-menu-img.svg);
			background-position: center;
			background-repeat: no-repeat;
			width: 24px;
			height: 24px;
			display: none;
			transition: background-image 0.5s;
		}
		.burger-menu-btn.active {
			background-image: url(../../assets/close-img.svg);
		}
	}
	.burger-menu {
		display: none;
		height: 100vh;
		position: absolute;
		top: 60px;
		left: 0px;
		visibility: hidden;
		opacity: 0;
		width: 100%;
		background-color: #070707;
		padding: 24px 16px 34px;
		background-image: url(../../../src/assets/experiense-bg-2.png);
		background-position: right -200px top 50%;
		background-repeat: no-repeat;
		transition: visibility 0.5s, opacity 0.5s, background 0.5s;
		z-index: 500;
		.header-menu-item-link {
			margin-bottom: 16px;
		}
		.header-menu-item-link.active {
			.header-menu-item {
				color: #fe6700;
			}
		}
		.header-menu-item {
			font-weight: 700;
			line-height: 37px;
		}
	}
	.burger-menu.active {
		background-position: right -120px top 50%;
		visibility: visible;
		opacity: 1;
	}
}

// ---------------------- hover ----------------------

@media (hover: hover) {
	.header {
		.header-row {
			.header-menu {
				.header-menu-item {
					transition: color 0.3s;
				}
				.header-menu-item-link {
					&:hover .header-menu-item {
						color: #fe6700;
					}
				}
				.header-menu-item-products {
					&:hover .header-menu-item-dropdown-products {
						display: block;
					}
				}
				.header-menu-item-services {
					&:hover .header-menu-item-dropdown-services {
						display: block;
					}
				}
				.header-menu-item-dropdown {
					.dropdown-link-wrapper {
						transition: color 0.3s;
						&:hover {
							color: #fe6700;
						}
					}
				}
			}
		}
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Large Desktop -------------
@media (max-width: 1500px) {
	.header {
		.header-row {
			.header-logo {
				width: 250px;
			}
			.header-menu {
				gap: 60px;
			}
		}
	}
}

// ------------- Laptop -------------
@media (max-width: 1200px) {
	.header {
		.header-container {
			padding: 0px 26px;
		}
		.header-row {
			margin-left: -30px;
			margin-right: -30px;
			.header-menu {
				gap: 25px;
			}
		}
	}
}

// ------------- Tablet -------------
@media (max-width: 992px) {
	.header {
		.header-row {
			margin-left: 0px;
			margin-right: 0px;
			.header-menu {
				display: none;
			}
			.desktop-header-btn {
				display: none;
			}
			.burger-menu-btn {
				display: block;
			}
		}
		.burger-menu {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.header {
		.header-row {
			max-height: 40px;
			.logo-link {
				height: 32px;
				width: 116px;
			}
		}
		.burger-menu {
			top: 40px;
			background-size: 90%;
		}
	}
}
