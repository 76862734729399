.error-page-section {
	height: 70vh;
	position: relative;
	.error-mesage {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-weight: 700;
		font-size: 60px;
		line-height: 80px;
		text-align: center;
	}
}
