.page-btn-row {
	margin-top: 40px;
	display: flex;
	gap: 30px;
	.page-btn-item {
		background: transparent;
		border: 2px solid #ffffff;
		padding: 27px 0px;
		color: #ffffff;
		font-family: 'Montserrat', sans-serif;
		font-size: 16px;
		font-weight: 700;
		line-height: 19px;
		max-width: 330px;
		min-width: 265px;
		width: 100%;
		background-repeat: no-repeat;
		background-position: 93% 50%;
		padding-right: 30px;
		cursor: pointer;
	}
	.page-btn-download {
		background-image: url(../../assets/file-img.svg);
	}
	.page-btn-form {
		background-image: url(../../assets/list-img.svg);
	}
}

@media (hover: hover) {
	.page-btn-row {
		.page-btn-item {
			transition: border 0.3s;
			&:hover {
				border-color: #fe6700;
			}
		}
	}
}

// ------------- Tablet -------------
@media (max-width: 992px) {
	.page-btn-row {
		gap: 10px;
		.page-btn-item {
			min-width: 255px;
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.page-btn-row {
		flex-direction: column;
		.page-btn-item {
			max-width: none;
			width: 100%;
		}
	}
}
