.video-section {
	position: relative;
	height: 810px;
	.video-bg {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		opacity: 0.5;
		object-fit: cover;
	}
	.video-section-img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		> img {
			object-fit: cover;
		}
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Laptop -------------
@media (max-width: 1200px) {
	.video-section {
		.video-section-img {
			width: 600px;
			> img {
				width: 100%;
			}
		}
	}
}

// ------------- Tablet -------------
@media (max-width: 992px) {
	.video-section {
		height: 500px;
		.video-section-img {
			width: 400px;
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.video-section {
		height: 225px;
		.video-section-img {
			width: 205px;
		}
	}
}
