a {
	text-decoration: none;
	color: #ffffff;
	display: block;
}
.page-item {
	padding: 32px 16px;
	border: 1px solid #ededed;
	border-radius: 16px;
	background: #1e222d;
	display: grid;
	grid-template-rows: 80px 1fr 32px;
	justify-content: space-between;
	gap: 10px;
	height: 100%;
	.page-item-title {
		font-size: 32px;
		font-weight: 700;
		line-height: 38px;
		letter-spacing: -0.04em;
		text-transform: uppercase;
	}
	.page-item-text {
		line-height: 24px;
		color: #7a8085;
		margin-bottom: 48px;
	}
	.page-item-link {
		align-items: center;
		gap: 11px;
	}
	.arrow-img {
		background-image: url(../../assets/orange-arrow-righ-img.svg);
		background-position: center;
		background-repeat: no-repeat;
		width: 20px;
		height: 20px;
	}
}

.services-page {
	.page-item {
		.page-item-text {
			color: #ffffff;
			margin-bottom: 16px;
		}
		a {
			color: #fe6700;
		}
	}
}

@media (hover: hover) {
	.page-item {
		transition: box-shadow 0.5s;
		&:hover {
			background: linear-gradient(
				126.29deg,
				rgba(160, 142, 236, 0.3) 9.59%,
				rgba(213, 207, 237, 0.09) 98.04%
			);
			box-shadow: 0px 30px 55px 20px #d075e333;
		}
	}
	a {
		&:hover .arrow-img {
			transform: translateX(30%);
		}
		.arrow-img {
			transition: transform 0.3s;
		}
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Mobile -------------
@media (max-width: 576px) {
	.page-item {
		padding: 24px 16px;
		grid-template-rows: 50px 1fr 32px;
		.page-item-title {
			font-size: 24px;
			line-height: 29px;
			letter-spacing: -0.04em;
		}
		.page-item-text {
			margin-bottom: 24px;
		}
	}
}
