.header-btn {
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 700;
	line-height: 19px;
	color: white;
	background-color: #fe6700;
	padding: 10px 20px;
	border: 2px solid transparent;
	border-radius: 8px;
	cursor: pointer;
}

// ---------------------- hover ----------------------

@media (hover: hover) {
	.header-btn {
		transition: border 0.3s;
		&:hover {
			border: 2px solid white;
		}
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Large Desktop -------------
@media (max-width: 1500px) {
	.header-btn {
		padding: 10px 16px;
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.header-btn {
		width: 100%;
		padding-top: 23px;
		padding-bottom: 23px;
		margin-top: 24px;
		margin-bottom: 65px;
	}
}
