.articles-section {
	text-align: center;
	padding-top: 80px;
	padding-bottom: 120px;
	.articles-subtitle {
		font-size: 19px;
		font-weight: 700;
		line-height: 23px;
		color: #fe6700;
	}
	.articles-title {
		font-size: 48px;
		font-weight: 700;
		line-height: 59px;
		margin-top: 12px;
		margin-bottom: 24px;
	}
	.articles-wrapper,
	.articles-tablet-wrapper {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 30px;
		a {
			text-decoration: none;
			color: #ffffff;
			height: 100%;
		}
		.article-item {
			border: 1px solid #bdbabac7;
			background-color: #1e222d;
			text-align: left;
			height: 100%;
			display: grid;
			grid-template-rows: minmax(200px, 380px) 1fr 50px;
			flex-direction: column;
			justify-content: space-between;
			gap: 20px;
			.article-image-wrapper {
				max-height: 300px;
				overflow: hidden;
				> img {
					width: 100%;
					transition: transform 0.3s;
				}
			}
			.article-title {
				font-size: 20px;
				font-weight: 700;
				line-height: 30px;
				padding-left: 20px;
				padding-right: 20px;
				margin-top: 20px;
				margin-bottom: 83px;
				text-transform: uppercase;
			}
			.article-text-wrapper {
				padding: 20px;
				padding-top: 0;
			}
			.article-link {
				padding-left: 20px;
				padding-right: 20px;
				color: #fe6700;
				font-size: 16px;
				font-weight: 700;
				line-height: 16px;
				align-items: center;
				gap: 16px;
				.article-link-img {
					background-image: url(../../../../assets/orange-plus-img.svg);
					background-position: center;
					background-repeat: no-repeat;
					width: 12px;
					height: 8px;
				}
			}
			&:hover .article-image-wrapper > img {
				transform: scale(110%);
			}
		}
	}
	.articles-tablet-wrapper {
		display: none;
		.article-item {
			width: 90%;
			margin-right: 10px;
			margin-left: 10px;
		}
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Large Laptop -------------
@media (max-width: 1300px) {
	.articles-section {
		.articles-wrapper {
			grid-template-columns: 1fr 1fr;
		}
	}
}

// ------------- Laptop -------------
@media (max-width: 1200px) {
}

// ------------- Tablet -------------
@media (max-width: 992px) {
	.articles-section {
		.articles-wrapper {
			display: none;
		}
		.articles-tablet-wrapper {
			display: block;
			.article-item {
				min-height: 590px;
				.article-image-wrapper {
					display: flex;
					align-items: center;
				}
			}
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.articles-section {
		padding: 48px 0px;
		.articles-subtitle {
			font-size: 16px;
			line-height: 20px;
		}
		.articles-title {
			font-size: 24px;
			line-height: 29px;
		}
		.articles-tablet-wrapper {
			margin-left: -16px;
			margin-right: -16px;
			.article-item {
				grid-template-rows: minmax(240px, 270px) 1fr 35px;
				width: 85%;
				.article-image-wrapper {
					max-height: 190px;
				}
				.article-title {
					margin-top: 16px;
					margin-bottom: 16px;
					font-size: 18px;
					line-height: 30px;
				}
				.article-text-wrapper {
					padding-bottom: 0;
				}
			}
		}
	}
}

// ------------- Small Mobile -------------
@media (max-width: 420px) {
	.articles-section {
		.articles-tablet-wrapper {
			.article-item {
				min-height: 603px;
				grid-template-rows: minmax(240px, 300px) 1fr 35px;
			}
		}
	}
}

@media (max-width: 340px) {
	.articles-section {
		.articles-tablet-wrapper {
			.article-item {
				min-height: 633px;
				.article-title {
					font-size: 17px;
				}
			}
		}
	}
}
