.introduce-section {
	background-image: url(../../../../assets/introduce-section-bg.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: calc(100vh - 106px);
	border-bottom-left-radius: 50px;
	border-bottom-right-radius: 50px;
	margin-top: 106px;
	position: relative;
	.introduce-section-bg {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background: #00000033;
	}
	.introduce-content {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 1000px;
		height: 100%;
		background-image: url(../../../../assets/introduce-content-bg.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 110%;
		display: flex;
		align-items: center;
	}
	.introduce-title {
		font-size: 54px;
		font-weight: 700;
		line-height: 66px;
		margin-bottom: 20px;
		text-transform: uppercase;
	}
	.introduce-button-wrapper {
		gap: 30px;
		margin-top: 88px;
		.introduce-btn-meet {
			border: none;
			background-color: transparent;
			color: #fe6700;
			font-family: 'Montserrat', sans-serif;
			font-size: 19px;
			font-weight: 700;
			line-height: 19px;
			cursor: pointer;
			> a {
				color: #fe6700;
			}
			.introduce-btn-meet-img {
				background-image: url(../../../../assets/btn-arrow-img.svg);
				background-position: center;
				background-repeat: no-repeat;
				width: 19px;
				height: 19px;
				margin-right: 6px;
			}
		}
	}
	.introduce-btn-info {
		font-family: 'Montserrat', sans-serif;
		font-size: 19px;
		font-weight: 700;
		line-height: 19px;
		max-width: 450px;
		color: #ffffff;
		background-color: transparent;
		border: 2px solid white;
		padding: 27px 68px;
		background-image: url(../../../../assets/plus-img.svg);
		background-repeat: no-repeat;
		background-position: 90% 50%;
		cursor: pointer;
	}
	.introduce-btn-info-mobile {
		display: none;
	}
}

// ---------------------- hover ----------------------

@media (hover: hover) {
	.introduce-section {
		.introduce-button-wrapper {
			.introduce-btn-meet {
				&:hover .introduce-btn-meet-img {
					transform: translateX(-30%);
				}
				.introduce-btn-meet-img {
					transition: transform 0.3s;
				}
			}
		}
		.introduce-btn-info {
			transition: background 0.3s;
			&:hover {
				background-color: rgba(255, 255, 255, 0.2);
			}
		}
	}
}

// ------------- Large Desktop -------------
@media (max-width: 1500px) {
	.introduce-section {
		.introduce-content {
			width: 70%;
		}
	}
}

// ------------- Laptop -------------
@media (max-width: 1200px) {
	.introduce-section {
		.introduce-content {
			width: 88%;
		}
	}
}

// ------------- Tablet -------------
@media (max-width: 992px) {
	.introduce-section {
		.introduce-content {
			width: 92%;
		}
		.introduce-title {
			font-size: 36px;
			line-height: 45px;
			margin-bottom: 14px;
		}
		.introduce-button-wrapper {
			flex-direction: column-reverse;
			margin-top: 12px;
			gap: 92px;
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.introduce-section {
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		.introduce-content {
			width: 93%;
		}
		.introduce-title {
			font-size: 24px;
			line-height: 29px;
		}
		.introduce-btn-info {
			display: none;
		}
		.introduce-btn-info-mobile {
			display: block;
			width: 100%;
			max-width: none;
			position: absolute;
			bottom: 60px;
		}
	}
}

// ------------- Small Mobile -------------
@media (max-width: 380px) {
	.introduce-section {
		.introduce-content {
			width: 91%;
		}
	}
}
