.search-component {
	margin-bottom: 44px;
	.search-container {
		text-align: center;
	}
	.search {
		background-color: #070707;
		width: 690px;
		border: 1px solid #ffffff;
		border-radius: 50px;
		padding: 10px 28px;
		font-family: 'Roboto', sans-serif;
		font-weight: 300;
		line-height: 24px;
		color: white;
		background-image: url(../../../../assets/search-img.svg);
		background-repeat: no-repeat;
		background-position: 95% 50%;
		&::placeholder {
			font-family: 'Roboto', sans-serif;
			font-weight: 300;
			line-height: 24px;
			color: #888888;
		}
		&:focus {
			outline: none;
		}
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Tablet -------------
@media (max-width: 992px) {
	.search-component {
		.search {
			width: 100%;
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
}
