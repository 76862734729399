.offer-section {
	padding-top: 108px;
	padding-bottom: 117px;
	.offer-title-row {
		justify-content: space-between;
	}
	.offer-title-wrapper {
		width: 50%;
		margin-left: 150px;
		.offer-title {
			font-size: 64px;
			font-weight: 700;
			line-height: 78px;
			margin-bottom: 31px;
			text-transform: uppercase;
		}
		.offer-subtitle {
			line-height: 27px;
		}
	}
	.offer-btn-wrapper {
		.offer-btn {
			position: relative;
			width: 120px;
			height: 120px;
			border: 1px solid #e7e9ed;
			border-radius: 50%;
			box-shadow: 0px 13px 32px 0px #c7c7c740;
			background-position: center;
			background-repeat: no-repeat;
			background-size: 80% 80%;
			cursor: pointer;
			.offer-btn-small {
				width: 96px;
				height: 96px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				border-radius: 50%;
				background: url(../../../../assets/arrow-right.svg),
					linear-gradient(238.22deg, #666fc7 3.22%, #ab54c2 103.79%);
				background-position: center;
				background-repeat: no-repeat;
				box-shadow: 0px 50px 55px 0px #d484e899;
			}
		}
		.offer-btn-label {
			text-align: center;
			text-transform: uppercase;
			margin-top: 40px;
			font-size: 14px;
			font-weight: 800;
			line-height: 17px;
		}
	}
	.offer-mobile-btn-wrapper {
		display: none;
	}
	.offer-items-wrapper {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 30px;
		margin-top: 60px;
		.offer-item {
			border: 1px solid #e3e3e3;
			background-color: #1e222d;
			border-radius: 16px;
			padding: 50px 40px;
			> a {
				text-decoration: none;
				color: white;
			}
			cursor: pointer;
			.offer-item-img {
				width: 72px;
				height: 72px;
				border: 1px solid white;
				border-radius: 50%;
				margin-bottom: 37px;
				background-repeat: no-repeat;
				background-position: 80% 30%;
			}
			.offer-item-title {
				font-size: 20px;
				font-weight: 700;
				line-height: 20px;
				margin-bottom: 16px;
			}
			.offer-item-subtitle {
				line-height: 27px;
			}
		}
		.offer-item-1 {
			.offer-item-img {
				background-image: url(../../../../assets/offer-item-img-1.png);
			}
		}
		.offer-item-2 {
			.offer-item-img {
				background-image: url(../../../../assets/offer-item-img-2.png);
			}
		}
		.offer-item-3 {
			.offer-item-img {
				background-image: url(../../../../assets/offer-item-img-3.png);
			}
		}
		.offer-item-4 {
			.offer-item-img {
				background-image: url(../../../../assets/offer-item-img-4.png);
			}
		}
	}
}

@media (hover: hover) {
	.offer-section {
		.offer-btn-wrapper {
			.offer-btn {
				&:hover .offer-btn-small {
					box-shadow: 0px 50px 65px 20px #d484e899;
				}
				.offer-btn-small {
					transition: box-shadow 0.5s;
				}
			}
		}
		.offer-items-wrapper {
			.offer-item {
				transition: box-shadow 0.5s;
				&:hover {
					background: linear-gradient(
						126.29deg,
						rgba(160, 142, 236, 0.3) 9.59%,
						rgba(213, 207, 237, 0.09) 98.04%
					);
					box-shadow: 0px 30px 55px 20px #d075e333;
				}
			}
		}
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Large Desktop -------------
@media (max-width: 1300px) {
	.offer-section {
		.offer-items-wrapper {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr;
		}
	}
}

// ------------- Tablet -------------
@media (max-width: 992px) {
	.offer-section {
		.offer-title-row {
			display: block;
			.offer-title-wrapper {
				margin-left: 0;
				width: 100%;
			}
			.offer-btn-wrapper {
				display: none;
			}
		}
		.offer-mobile-btn-wrapper {
			display: block;
			width: 120px;
			margin: 24px auto 0px auto;
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.offer-section {
		padding-top: 48px;
		padding-bottom: 48px;
		.offer-title-wrapper {
			.offer-title {
				font-size: 24px;
				line-height: 29px;
				text-align: center;
			}
		}
		.offer-items-wrapper {
			grid-template-columns: 1fr;
			margin-top: 24px;
			gap: 16px;
			.offer-item {
				padding: 16px;
			}
		}
		.offer-mobile-btn-wrapper {
			width: 80px;
			.offer-btn {
				width: 80px;
				height: 80px;
				.offer-btn-small {
					width: 64px;
					height: 64px;
				}
			}
			.offer-btn-label {
				margin-top: 16px;
			}
		}
	}
}
