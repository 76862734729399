.tradingview-widget-copyright {
	display: none;
}
.tradingview-widget-container {
	.label-dzbd7lyV {
		.snap-dzbd7lyV {
			.end-dzbd7lyV {
				right: -30px;
				visibility: hidden;
			}
		}
	}
}
