.page-container {
	display: grid;
	grid-template-columns: 1fr 380px;
	align-items: flex-start;
	gap: 30px;
	margin-top: 32px;
	margin-bottom: -80px;
	.page-content-title {
		font-family: 'Roboto', sans-serif;
		text-transform: uppercase;
		margin-bottom: 18px;
		font-size: 24px;
		font-weight: 700;
		line-height: 30px;
	}
	.page-content-subtitle {
		margin-bottom: 32px;
		font-family: 'Roboto', sans-serif;
		text-transform: uppercase;
		font-size: 20px;
		font-weight: 700;
		line-height: 24px;
	}
	.service-title {
		font-family: 'Roboto', sans-serif;
		text-transform: uppercase;
		font-size: 32px;
		font-weight: 700;
		line-height: 39px;
		margin-bottom: 24px;
	}
	.service-subtitle {
		font-family: 'Roboto', sans-serif;
		text-transform: uppercase;
		font-size: 18px;
		font-weight: 700;
		line-height: 22px;
		margin-bottom: 8px;
	}
	.page-content-text {
		margin-bottom: 24px;
		line-height: 26px;
		> span {
			font-weight: 700;
		}
	}
	.no-margin {
		margin-bottom: 0px;
	}
	.page-content-img-wrapper {
		margin-bottom: 32px;
		max-height: 420px;
		display: flex;
		align-items: center;
		overflow: hidden;
		> img {
			width: 100%;
		}
	}
	.page-content-table {
		width: 100%;
		border: 1px solid #ffffff;
		text-transform: uppercase;
		thead {
			background: #ffffff1a;
		}
		th,
		td {
			border: 1px solid #ffffff;
			padding: 18px 0px;
		}
		td {
			text-align: center;
		}
		.table-cell-alighn-left {
			text-align: left;
			padding-left: 36px;
		}
	}
}

.post-page {
	.page-content-title {
		line-height: 44px;
		font-family: 'Roboto', sans-serif;
	}
	.page-content-subtitle {
		font-family: 'Roboto', sans-serif;
		text-transform: uppercase;
		font-size: 16px;
		line-height: 26px;
		margin-bottom: 24px;
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Laptop -------------
@media (max-width: 1200px) {
	.page-container {
		grid-template-columns: 1fr 300px;
	}
}

// ------------- Tablet -------------
@media (max-width: 992px) {
	.page-container {
		grid-template-columns: 1fr;
		.sticky-box {
			position: static !important;
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.page-container {
		.page-content-title {
			font-size: 18px;
			line-height: 27px;
			margin-bottom: 8px;
		}
		.page-content-text {
			font-size: 16px;
			line-height: 26px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.page-content-img-wrapper {
			max-height: 205px;
			margin-left: -16px;
			margin-right: -16px;
			margin-bottom: 24px;
		}
		.service-title {
			font-size: 18px;
			line-height: 27px;
			margin-bottom: 8px;
		}
		.service-subtitle {
			font-size: 16px;
			line-height: 22.4px;
			margin-bottom: 8px;
		}
		.page-content-table {
			font-size: 12px;
			line-height: 17px;
			.table-cell-alighn-left {
				padding-left: 13px;
			}
		}
	}
	.post-page {
		.page-content-subtitle {
			margin-bottom: 8px;
		}
	}
}
