.contacts-page-section {
	.contacts-row {
		padding-top: 60px;
		padding-bottom: 100px;
		gap: 120px;
		.contacts-content {
			width: 50%;
			line-height: 36px;
			.contact-item-phone {
				padding: 32px 0px;
			}
			.contacts-item-mail {
				padding-top: 11px;
				padding-bottom: 32px;
			}
			.contacts-title {
				font-size: 35px;
				font-weight: 700;
				line-height: 43px;
				margin-bottom: 20px;
			}
			.contacts-subtitle {
				font-size: 24px;
				font-weight: 800;
			}
			.contacts-text {
				> span {
					font-weight: 700;
				}
			}
			.contacts-item-row {
				gap: 11px;
				.item-img {
					min-width: 16px;
					height: 16px;
					margin-top: 6px;
					background-position: center;
					background-repeat: no-repeat;
				}
				.item-img-phone {
					background-image: url(../../assets/phone-img.svg);
				}
				.item-img-location {
					background-image: url(../../assets/location-img.svg);
				}
				.item-img-uae {
					background-image: url(../../assets/uae-img.svg);
				}
				.item-img-oman {
					background-image: url(../../assets/oman-img.svg);
				}
				.item-img-mexico {
					background-image: url(../../assets/mexico-img.svg);
				}
				.item-img-mail {
					background-image: url(../../assets/mail-img.svg);
				}
			}
			.contacts-item-list-row {
				font-size: 19px;
				font-weight: 700;
				line-height: 19px;
				color: #fe6700;
				text-decoration: underline;
				align-items: center;
				gap: 9px;
				margin-left: 42px;
				cursor: pointer;
				.contacts-item-list {
					width: 24px;
					height: 24px;
					background-image: url('../../assets/contacts-list-img.svg');
					background-position: center;
					background-repeat: no-repeat;
				}
			}
		}
		.form-container {
			width: 50%;
			text-align: left;
			position: relative;
			.form-anker {
				position: absolute;
				top: -114px;
			}
		}
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Desctop -------------
@media (max-width: 1500px) {
}

// ------------- Laptop -------------
@media (max-width: 1200px) {
}

// ------------- Tablet -------------
@media (max-width: 992px) {
	.contacts-page-section {
		.contacts-row {
			flex-direction: column;
			gap: 24px;
			.contacts-content {
				width: 100%;
				.contacts-item-list-row {
					justify-content: center;
					margin-left: 0;
				}
			}
			.form-container {
				width: 100%;
			}
		}
		.form-component {
			.subtitle,
			.title {
				text-align: center;
			}
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.contacts-page-section {
		line-height: 29px;
		.contacts-row {
			padding-top: 24px;
			padding-bottom: 48px;
			gap: 49px;
			.contacts-content {
				.contacts-title {
					font-size: 18px;
					line-height: 22px;
				}
				.contacts-subtitle {
					font-size: 18px;
					line-height: 36px;
				}
				.contact-item-phone {
					padding-top: 24px;
					padding-bottom: 16px;
				}
				.contacts-item-row-country {
					margin-bottom: 16px;
				}
				.contacts-item-mail {
					padding-bottom: 49px;
				}
			}
			.form-container {
				.form-anker {
					top: -130px;
				}
			}
		}
	}
}
