.products-page {
	.products-links-row {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		padding-top: 60px;
		padding-bottom: 100px;
		gap: 26px;
	}
	.form-bg {
		position: relative;
		overflow: hidden;
		padding-bottom: 60px;
		.form-bg-img {
			position: absolute;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
		.form-bg-img-1 {
			background-image: url(../../assets/experiense-bg-1.png);
			top: 0px;
			left: -200px;
			width: 530px;
			height: 530px;
		}
		.form-bg-img-2 {
			bottom: 0px;
			right: -100px;
			width: 370px;
			height: 370px;
			background-image: url(../../assets/experiense-bg-2.png);
		}
		.form-container {
			margin: 0 auto;
			width: 700px;
		}
	}
}

// ---------------------- Adaptive ----------------------

// ------------- Laptop -------------
@media (max-width: 1200px) {
	.products-page {
		.products-links-row {
			grid-template-columns: 1fr 1fr;
		}
	}
}

// ------------- Tablet -------------
@media (max-width: 992px) {
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.products-page {
		.products-links-row {
			grid-template-columns: 1fr;
			padding: 32px 0px 48px;
			gap: 16px;
		}
		.form-bg {
			.form-bg-img {
				display: none;
			}
			.form-container {
				width: 100%;
				padding: 0px 16px;
			}
		}
	}
}
