.footer-content {
	background-color: #1e222d;
	position: relative;
	padding-top: 47px;
	padding-bottom: 34px;
	background-image: url(../../../../assets/footer-bg.png);
	background-position: right -220px bottom -90px;
	background-repeat: no-repeat;
	background-size: 464px;
	border-top-left-radius: 60px;
	border-top-right-radius: 60px;
	a {
		text-decoration: none;
		color: white;
	}
	.footer-row {
		border-top-left-radius: 60px;
		border-top-right-radius: 60px;
		display: flex;
		gap: 70px;
		.footer-row-logo-wrapper {
			width: 33%;
			.footer-logo {
				background-image: url(../../../../assets/footer-logo.png);
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				height: 87px;
				width: 311px;
			}
			.footer-logo-wrapper-subtitle {
				font-family: 'Roboto', sans-serif;
				font-size: 16px;
				font-weight: 400;
				line-height: 27px;
				color: #fe6700;
				margin-top: 11px;
				margin-bottom: 7px;
			}
			.footer-logo-wrapper-title {
				font-size: 48px;
				font-weight: 600;
				line-height: 58px;
				text-transform: uppercase;
			}
		}
		.footer-btn {
			margin-top: 32px;
			background: linear-gradient(114.92deg, #fd4360 -43.3%, #d743be 132.23%);
			box-shadow: 0px 7px 32px 0px #fe670099;
		}
		.footer-row-title {
			font-size: 18px;
			font-weight: 600;
			line-height: 27px;
			margin-bottom: 16px;
		}
		.footer-row-subtitle {
			font-family: 'Plus Jakarta Sans', sans-serif;
			font-size: 16px;
			font-weight: 400;
			line-height: 32px;
		}
		.footer-row-item {
			font-family: 'Plus Jakarta Sans', sans-serif;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			margin-bottom: 16px;
		}
		.footer-row-navigation {
			min-width: 130px;
			.footer-row-contacts-container-tablet {
				display: none;
			}
		}
		.footer-row-contacts {
			.footer-row-addresses {
				display: flex;
				margin-top: 24px;
				gap: 16px;
				.footer-row-address {
					display: flex;
					align-items: flex-start;
					gap: 16px;
					max-width: 230px;
					.footer-row-address-img {
						background-position: center;
						background-repeat: no-repeat;
						min-width: 12px;
						min-height: 12px;
					}
					.footer-row-address-item {
						font-family: 'Plus Jakarta Sans', sans-serif;
						font-size: 12px;
						font-weight: 400;
						line-height: 18px;
					}
					.footer-row-address-item-bold {
						font-family: 'Plus Jakarta Sans', sans-serif;
						font-size: 12px;
						font-weight: 700;
						line-height: 18px;
					}
				}
				.footer-row-address-1 {
					.footer-row-address-img {
						background-image: url(../../../../assets/uae-img.svg);
					}
				}
				.footer-row-address-2 {
					.footer-row-address-img {
						background-image: url(../../../../assets/oman-img.svg);
					}
				}
				.footer-row-address-3 {
					.footer-row-address-img {
						background-image: url(../../../../assets/mexico-img.svg);
					}
				}
			}
		}
	}
	.footer-copyright {
		text-align: center;
		margin-top: 46px;
		font-family: 'Plus Jakarta Sans', sans-serif;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		> span {
			font-weight: 700;
		}
	}
}

@media (hover: hover) {
	.footer {
		.footer-content {
			.footer-row {
				.footer-btn {
					transition: box-shadow 0.5s;
					&:hover {
						box-shadow: 0px 7px 46px 10px #fe670099;
					}
				}
				.footer-row-navigation {
					.footer-row-item {
						transition: color 0.5s;
						&:hover {
							color: #fe6700;
						}
					}
				}
			}
		}
	}
}

// Adaptive

@media (max-width: 1300px) {
	.footer-content {
		.footer-row {
			.footer-row-logo-wrapper {
				.footer-logo-wrapper-title {
					margin-top: 50px;
				}
			}
		}
	}
}

// ------------- laptop -------------
@media (max-width: 1200px) {
	.footer-content {
		background-position: right -150px top 100%;
		.footer-row {
			flex-wrap: wrap;
			.footer-row-logo-wrapper {
				width: 60%;
				.footer-logo-wrapper-title {
					margin-top: 0px;
				}
			}
		}
	}
}

// ------------- Tablet -------------
@media (max-width: 992px) {
	.footer-content {
		background-position: right -240px top 50%;
		.footer-row {
			display: grid;
			grid-template-columns: 1fr 2fr;
			gap: 26px 50px;
			.footer-row-logo-wrapper {
				grid-column-start: 1;
				grid-column-end: 3;
			}
			.footer-row-contacts {
				.footer-row-addresses {
					flex-direction: column;
					gap: 26px;
				}
			}
			.footer-row-logo-wrapper {
				width: 100%;
				.footer-logo-wrapper-title {
					font-size: 32px;
					line-height: 44px;
				}
			}
		}
	}
}

// ------------- Mobile -------------
@media (max-width: 576px) {
	.footer-content {
		background-position: right -310px bottom 25%;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		.footer-row {
			grid-template-columns: 1fr;
			.footer-row-logo-wrapper {
				grid-column-start: 1;
				grid-column-end: 2;
				.footer-logo {
					width: 180px;
					height: 50px;
				}
				.footer-logo-wrapper-title {
					font-size: 28px;
					line-height: 40px;
				}
			}
			.footer-row-navigation {
				display: flex;
				justify-content: space-between;
				.footer-row-contacts-container-tablet {
					display: block;
					.footer-row-title {
						margin-bottom: 4px;
					}
				}
			}
			.footer-row-contacts-container {
				display: none;
			}
			.footer-btn {
				width: 100%;
			}
		}
		.footer-copyright {
			margin-top: 55px;
		}
	}
}
